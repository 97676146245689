<template>
  <div class="page-content-wrapper">
    <duka-modal
      v-if="requestModal"
      @close="handleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#041e42'"
    >
      <span slot="head" style="padding-top:10px">Create a new Offer</span>
      <div slot="body">
        <div class="row">
          <div class="col-xl-10 mx-auto">
            <h4 class="mb-1">Place Withdraw Request</h4>
            <p>Create a new withdraw request</p>
            <div class="clearfix"></div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newRequest.amount_requested"
                />
                <label for="gr_login_password">Amount</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <p
              class="text-danger mb-0"
              v-if="
                Number(newRequest.amount_requested) > Number(balance.balance)
              "
            >
              You can't place request. Requested Amount is greater than your
              actual Balance
            </p>
            <duka-button
              :loadText="''"
              :caption="'Confirm Request'"
              @btn-role="setRequest"
              :classes="'btn btn-lg btn-primary'"
              :disabler="
                !newRequest.amount_requested ||
                  Number(newRequest.amount_requested) > Number(balance.balance)
              "
              :activator="'creatingrequest'"
            ></duka-button>
          </div>
        </div>
      </div>
    </duka-modal>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <div class="page-title-box">
            <div class="btn-group float-right">
              <ol class="breadcrumb hide-phone p-0 m-0">
                <li class="breadcrumb-item">
                  <a href="javascript:void(0)">Company</a>
                </li>
                <li class="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
            <h4 class="page-title">Dashboard</h4>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="row">
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body py-2 border-bottom">
                  <h6>Total Offers Amount</h6>
                </div>
                <div class="card-body">
                  <h4 class="text-primary text-right">
                    USD {{ addComma(balance.total) || 0 }}
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body py-2 border-bottom">
                  <h6>Total Withdrawed Amount</h6>
                </div>
                <div class="card-body">
                  <h4 class="text-primary text-right">
                    USD {{ addComma(balance.withdraw) || 0 }}
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body py-2 border-bottom">
                  <h5 class="mb-0">Current Balance</h5>
                </div>
                <div class="card-body">
                  <h4 class="text-primary text-right">
                    USD {{ addComma(balance.balance) || 0 }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="card">
            <div class="card-body border-bottom">
              <h4>
                Your Company withdraw history
                <button
                  type="button"
                  class="btn btn-outline-primary waves-effect waves-light float-right"
                  @click="handleModal"
                >
                  <i class="dripicons-plus mr-2"></i>Place New
                </button>
              </h4>
            </div>
            <div class="card-body">
              <div class="table-responsive table-responsive-sm">
                <table class="table">
                  <thead>
                    <tr>
                      <th>$/N</th>
                      <th>Transaction code</th>
                      <th>Request date</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(request, index) in requests"
                      :key="'request' + index"
                    >
                      <td>{{ incrementIndex(index) }}</td>
                      <td>{{ request.withdraw_code }}</td>
                      <td>{{ request.request_date }}</td>
                      <td>{{ addComma(request.amount_requested) }}</td>
                      <td>
                        <span
                          class="badge badge-boxed badge-soft-warning"
                          v-if="!Number(request.granted)"
                          >Pending</span
                        >
                        <span
                          class="badge badge-boxed badge-soft-success"
                          v-else
                          >Completed</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomerAccount",
  data: () => ({
    user: {},
    balance: {},
    newRequest: {
      amount_requested: 50
    },
    loggedUser: {},
    resultFound: false,
    requestModal: false,
    requests: []
  }),
  created() {
    this.loadCurrentUser();
    this.loadCustomerBalance();
    this.getRequests();
  },
  methods: {
    handleModal() {
      this.requestModal = !this.requestModal;
    },
    setRequest() {
      let url = "customer/set-withdraw-request";
      var formData = this.formData(this.newRequest);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.clearObject(this.newRequest);
            this.handleModal();
          }
        })
        .catch(err => console.log(err));
    },
    loadCurrentUser() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-current-user")
        .then(response => {
          this.resultFound = true;
          this.user = response.data.user_details;
          this.loggedUser = response.data.user;
          if (!Number(response.data.user.active)) {
            this.toggleModal();
            this.getRequests();
          }
        });
    },
    loadCustomerBalance() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-balances")
        .then(response => {
          this.resultFound = true;
          this.balance = response.data;
        });
    },
    getRequests() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-requests")
        .then(response => {
          this.requests = response.data.requests;
        });
    }
  }
};
</script>
