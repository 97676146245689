<template>
  <section class="py-4">
    <duka-modal
      v-if="modalOpen && viewMode.offerMode"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#041e42'"
    >
      <span slot="head" style="padding-top:10px">Create a new Offer</span>
      <div slot="body">
        <div class="row">
          <div class="col-xl-10 mx-auto">
            <h4 class="mb-1">Offer details</h4>
            <p>Create new offer that can be shared to multiple clients</p>
            <div class="clearfix"></div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="offerNew.offer_title"
                />
                <label for="gr_login_password">Offer Title</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group row">
              <label class="col-md-5 my-1 control-label">Offer Currency</label>
              <div class="col-md-7">
                <div class="form-check-inline my-1">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio4"
                      name="customRadio"
                      class="custom-control-input"
                      value="USD"
                      v-model="offerNew.offer_currency"
                    />
                    <label class="custom-control-label" for="customRadio4"
                      >USD</label
                    >
                  </div>
                </div>
                <div class="form-check-inline my-1">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio5"
                      name="customRadio"
                      class="custom-control-input"
                      value="RWF"
                      v-model="offerNew.offer_currency"
                    />
                    <label class="custom-control-label" for="customRadio5"
                      >RWF</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="offerNew.offer_amount"
                />
                <label for="gr_login_password">Offer Amount</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                style="height:auto!important"
                rows="3"
                placeholder="Offer description"
                v-model="offerNew.offer_description"
              ></textarea>
            </div>
            <duka-button
              :loadText="''"
              :caption="'Create Offer'"
              @btn-role="createOffer"
              :classes="'btn btn-lg btn-primary'"
              :disabler="!offerNew.offer_amount || !offerNew.offer_title"
              :activator="'creatingoffer'"
            ></duka-button>
          </div>
        </div>
      </div>
    </duka-modal>

    <duka-modal-form
      v-if="modalOpen && viewMode.customerMode"
      @close="toggleModal"
      style="display:block"
      :modalSize="'default'"
      :headerColor="'#041e42'"
    >
      <span slot="head" style="padding-top:10px">Send offer to a customer</span>
      <div slot="body">
        <div class="row">
          <div class="col-xl-10 mx-auto">
            <h4 class="mb-0">Customer details</h4>
            <p>Customer you are sending this offer to</p>
            <div class="clearfix"></div>
            <hr />
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newOffer.customer_firstname"
                />
                <label for="gr_login_password">Customer FirstName</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newOffer.customer_lastname"
                />
                <label for="gr_login_password">Customer LastName</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newOffer.customer_email"
                />
                <label for="gr_login_password">Customer Email address</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>
            <div class="form-group">
              <span class="input-group to_be_hidden form-control">
                <input
                  type="text"
                  tabindex="2"
                  autocomplete="off"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="newOffer.customer_phone"
                />
                <label for="gr_login_password">Customer Phone number</label>
                <a href="javascript:void(0);" class="reset-input">×</a>
              </span>
            </div>

            <p class="my-2 text-muted text-center">
              The customer will receive an email containing instruction to
              proceed with payment processes
            </p>
            <duka-button
              :loadText="''"
              :caption="'Confirm and notify client'"
              @btn-role="setOffer"
              :classes="'btn btn-lg btn-primary'"
              :disabler="
                !newOffer.customer_email || !newOffer.customer_firstname
              "
              :activator="'proceedingoffer'"
            ></duka-button>
          </div>
        </div>
      </div>
    </duka-modal-form>

    <div class="container">
      <div class="row">
        <div class="col-lg-12 mx-auto">
          <div class="card">
            <div class="card-body border-bottom">
              <h4 class="header-title">
                List of offers
                <span class="badge badge-primary badge-pill rounded-circle">{{
                  dbResponse.offers.length
                }}</span>
                <button
                  type="button"
                  class="btn btn-primary waves-effect waves-light float-right"
                  @click="handleViewMode('offerMode')"
                >
                  <i class="dripicons-plus mr-2"></i>Add new
                </button>
              </h4>
            </div>
            <div class="card-body">
              <div
                class="d-flex flex-wrap justify-content-center justify-content-sm-between pb-3"
              >
                <div class="d-flex flex-wrap">
                  <div class="form-inline flex-nowrap pb-3">
                    <label class="mr-2 d-none d-sm-block" for="number"
                      >Show:</label
                    >
                    <select
                      class="form-control custom-select mr-sm-2"
                      id="number"
                      @change="changePaginationRows($event)"
                    >
                      <option>12</option>
                      <option>24</option>
                      <option>48</option>
                      <option>96</option> </select
                    ><small
                      class="form-text text-muted text-nowrap d-none d-sm-block"
                      >From {{ recordsLength }} Offers
                    </small>
                  </div>
                </div>
                <div class="form-inline d-md-flex flex-nowrap pb-3">
                  <input
                    type="search"
                    v-model="filterKey"
                    placeholder="Search for an offer"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="table-responsive table-responsive-sm">
                <table class="table table-sm mb-0 table-centered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date Created</th>
                      <th>Offer title</th>
                      <th>Offer Amount</th>
                      <th>Offer description</th>
                      <th>Customers</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(offer, index) in filteredRecords"
                      :key="'offer' + index"
                    >
                      <td>{{ incrementIndex(index) }}</td>
                      <td>{{ dukaHotelDate(offer.created_at) }}</td>
                      <td>
                        <span class="ellipsis-text">{{
                          offer.offer_title
                        }}</span>
                      </td>
                      <td>
                        {{
                          offer.offer_currency +
                            " " +
                            addComma(offer.offer_amount)
                        }}
                      </td>
                      <td>
                        <span class="ellipsis-text">{{
                          offer.offer_description
                        }}</span>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'offerspayment',
                            query: { 'offer-id': offer.id }
                          }"
                        >
                          <span class="badge badge-primary p-1">
                            <u>{{ offer.customers }} customers</u>
                          </span>
                        </router-link>
                      </td>
                      <td>
                        <button type="button" class="btn btn-sm btn-success">
                          <i class="dripicons-pencil"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm btn-info ml-2"
                          @click="
                            selectRecord(offer);
                            handleViewMode('customerMode');
                          "
                        >
                          <i class="ti-sharethis"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm btn-danger ml-2"
                        >
                          <i class="ti-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr class="pb-4 mb-2" />
              <pagination
                :current_page="currentPage"
                :row_count_page="rowCountPage"
                @page-update="pageUpdate"
                :total_records="totalRows"
                :page_range="pageRange"
                prevIcon="ti-arrow-left"
                nextIcon="ti-arrow-right"
                dotsIcon="ti-more"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import MyPaginations from "@/components/Paginations.vue";
export default {
  name: "Offers",
  components: {
    pagination: MyPaginations
  },
  data: () => ({
    newOffer: {
      customer_firstname: null,
      customer_lastname: null,
      customer_phone: null,
      customer_email: null
    },
    offerNew: {
      offer_title: null,
      offer_amount: null,
      offer_description: null,
      offer_currency: "USD"
    },
    viewMode: {
      offerMode: false,
      customerMode: false
    },
    offers: []
  }),
  created() {
    this.responseRef = "offers";
    this.paginationURL = "customer/get-offers";
    this.loadPaginationRecords();
  },
  methods: {
    handleViewMode(active_modal) {
      this.falseObject(this.viewMode, active_modal);
      this.toggleModal();
    },
    loadOffers() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-offers")
        .then(response => {
          this.offers = response.data.offers;
        });
    },
    setOffer() {
      let url = "customer/proceed-offer";
      this.newOffer.offer_id = this.choosenRow.id;
      this.newOffer.offer_amount = this.choosenRow.offer_amount;
      var formData = this.formData(this.newOffer);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.clearObject(this.newOffer);
            this.toggleModal();
            this.loadPaginationRecords();
          }
        })
        .catch(err => console.log(err));
    },
    createOffer() {
      let url = "customer/create-offer";

      var formData = this.formData(this.offerNew);
      let DispatchpParams = { formData: formData, url: url };
      this.$store
        .dispatch("dukaPostRequest", DispatchpParams)
        .then(response => {
          if (!response.data.error) {
            this.offers = response.data.offers;
            this.clearObject(this.offerNew);
            this.toggleModal();
          }
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
